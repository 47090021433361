import { animated, config, useSpring } from '@react-spring/web';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useRoute } from 'wouter';
import { useRefGetter } from '@ichingio/hooks';
import { wait } from '@ichingio/nn';
import { NavDropdown } from '@ichingio/ui';
import { useLayoutContext } from '~/lib/layout';
import { LIST_JOURNALS_ROUTE, LIST_DIVINATIONS_ROUTE } from '~/lib/routes';

const Content = () => {
  const [_, navigate] = useLocation();
  const { scrollElement } = useLayoutContext();
  const [isDivinations] = useRoute(LIST_DIVINATIONS_ROUTE);
  const [isJournals] = useRoute(LIST_JOURNALS_ROUTE);
  let content: string | null = null;

  if (isDivinations) {
    content = 'Divinations';
  }
  if (isJournals) {
    content = 'Journals';
  }

  const scrollUp = useCallback(() => {
    if (scrollElement) {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [scrollElement]);

  return (
    <NavDropdown.Root
      trigger={
        <NavDropdown.Trigger tabIndex={1} ml="1px">
          {content}
        </NavDropdown.Trigger>
      }
    >
      <NavDropdown.Content alignOffset={-4} minWidth="10rem">
        <NavDropdown.Item
          isSelected={isDivinations}
          onSelect={async () => {
            if (!isDivinations) {
              navigate(LIST_DIVINATIONS_ROUTE);
            }

            await wait(50);
            scrollUp();
          }}
        >
          Divinations
        </NavDropdown.Item>
        <NavDropdown.Item
          isSelected={isJournals}
          onSelect={async () => {
            if (!isJournals) {
              navigate(LIST_JOURNALS_ROUTE);
            }

            await wait(50);
            scrollUp();
          }}
        >
          Journals
        </NavDropdown.Item>
      </NavDropdown.Content>
    </NavDropdown.Root>
  );
};

const SelectorDropdown = () => {
  const { scrollElement } = useLayoutContext();
  const [isVisible, toggleVisibility] = useState(false);
  const getIsVisible = useRefGetter(isVisible);

  useEffect(() => {
    if (scrollElement) {
      let prevScrollTop = scrollElement.scrollTop;

      const handleScroll: EventListener = () => {
        const current = getIsVisible();
        const updates =
          scrollElement.scrollTop > 128 &&
          scrollElement.scrollTop <= prevScrollTop;

        if (current !== updates) {
          toggleVisibility(updates);
        }

        prevScrollTop = scrollElement.scrollTop;
      };

      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollElement, getIsVisible]);

  const styles = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
    },
    config: config.stiff,
  });

  return (
    <animated.div style={styles}>
      <Content />
    </animated.div>
  );
};

export default SelectorDropdown;
