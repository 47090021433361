import { useLocation, useRoute } from 'wouter';
import { Stack, styled } from 'styled-system/jsx';
import { Heading, Touchable } from '@ichingio/ui';
import { useBreakpoints } from '@ichingio/viewport';
import {
  DIVINATION_ROUTE,
  JOURNAL_ROUTE,
  LIST_DIVINATIONS_ROUTE,
  LIST_JOURNALS_ROUTE,
} from '~/lib/routes';

const ListHeader = () => {
  const { breakpoint } = useBreakpoints();
  const [_, navigate] = useLocation();
  const [isDivination] = useRoute(DIVINATION_ROUTE);
  const [isDivinations] = useRoute(LIST_DIVINATIONS_ROUTE);
  const [isJournal] = useRoute(JOURNAL_ROUTE);
  const [isJournals] = useRoute(LIST_JOURNALS_ROUTE);

  return (
    <Stack direction="row" gap={1.5}>
      <Touchable onPress={() => navigate(LIST_DIVINATIONS_ROUTE)}>
        <Heading
          color={isDivination || isDivinations ? 'text' : 'zinc.300'}
          fontWeight={500}
          size={breakpoint === 1 ? '2xl' : 'lg'}
        >
          Divinations
        </Heading>
      </Touchable>
      <styled.span
        color="zinc.300"
        fontSize={breakpoint === 1 ? '1.68rem' : '1rem'}
      >
        /
      </styled.span>
      <Touchable onPress={() => navigate(LIST_JOURNALS_ROUTE)}>
        <Heading
          color={isJournal || isJournals ? 'text' : 'zinc.300'}
          fontWeight={500}
          size={breakpoint === 1 ? '2xl' : 'lg'}
        >
          Journals
        </Heading>
      </Touchable>
    </Stack>
  );
};

export default ListHeader;
