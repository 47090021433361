import { useMetro } from '@prtcl/plonk-hooks';
import { useState } from 'react';
import { Stack, type StackProps, styled } from 'styled-system/jsx';

const DURATION = 750;

const LoaderDot = styled('span', {
  base: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.5rem',
    height: '1.5rem',
    justifyContent: 'center',
    lineHeight: '1rem',
    marginTop: '-0.15rem',
    opacity: 0,
    transition: `opacity ${DURATION / 3}ms linear`,
  },
});

const Loader = (props: StackProps) => {
  const [state, setState] = useState(0);

  useMetro(
    () => {
      setState((prevState) => {
        if (prevState === 2) {
          return 0;
        }

        return prevState + 1;
      });
    },
    { time: DURATION },
  );

  return (
    <Stack
      alignItems="center"
      color="text"
      flexDirection="row"
      gap={0}
      height={4}
      justifyContent="center"
      px={2}
      width="fit-content"
      {...props}
    >
      {[0, 1, 2].map((n) => (
        <LoaderDot key={n} opacity={state === n ? 1 : 0.5}>
          &bull;
        </LoaderDot>
      ))}
    </Stack>
  );
};

export default Loader;
