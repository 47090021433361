import { lazy, useRef, type PropsWithChildren, Suspense } from 'react';
import {
  JournalEditorContext,
  useJournalEditorApi,
} from './hooks/useJournalEditor';

const JournalEditor = lazy(() => import('./JournalEditor'));

const JournalProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const context = useJournalEditorApi();
  const hasOpenedOnce = useRef(false);

  if (context.isOpen && !hasOpenedOnce.current) {
    hasOpenedOnce.current = true;
  }

  return (
    <JournalEditorContext.Provider value={context}>
      {children}
      <Suspense>{hasOpenedOnce.current && <JournalEditor />}</Suspense>
    </JournalEditorContext.Provider>
  );
};

export default JournalProvider;
