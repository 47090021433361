import { useEffect, useMemo } from 'react';
import usePrevious from 'react-use/esm/usePrevious';
import { useLocation, useRoute } from 'wouter';
import { useBreakpoints } from '@ichingio/viewport';
import {
  DIVINATION_ROUTE,
  LIST_DIVINATIONS_ROUTE,
  type DivinationParams,
} from '~/lib/routes';
import { useDivinationView } from '~/modals/DivinationView';

const useDivinationRouter = () => {
  const { isMobile } = useBreakpoints();
  const [isDivinationRoute, params] =
    useRoute<DivinationParams>(DIVINATION_ROUTE);
  const [location, navigate] = useLocation();
  const { viewDivinationResult, isOpen, closeViewer } = useDivinationView();

  const prevLocation = usePrevious(location);
  const prevIsOpen = usePrevious(isOpen);
  const prevIsDivinationRoute = usePrevious(isDivinationRoute);

  useEffect(() => {
    if (isOpen) {
      if (!isDivinationRoute && prevIsDivinationRoute) {
        closeViewer();
      }
    } else {
      if (isDivinationRoute && !prevIsDivinationRoute && params?.divinationId) {
        viewDivinationResult(params.divinationId);
      }
      // During the related divinations transition we can have a close event while still on the view router.
      if (isDivinationRoute && prevIsOpen) {
        navigate(LIST_DIVINATIONS_ROUTE);
      }
    }
  }, [
    closeViewer,
    isDivinationRoute,
    isMobile,
    isOpen,
    location,
    navigate,
    params,
    prevIsDivinationRoute,
    prevIsOpen,
    prevLocation,
    viewDivinationResult,
  ]);

  useEffect(() => {
    return () => {
      closeViewer();
    };
  }, [closeViewer]);

  return useMemo(() => {
    return (to: string) => {
      navigate(to);
    };
  }, [navigate]);
};

export default useDivinationRouter;
