import { type HTMLStyledProps, styled } from 'styled-system/jsx';
import { type ButtonProps } from './Button';
import { buttonStyle } from './lib/style';

const StyledLink = styled('a', buttonStyle);

export type LinkButtonProps = Omit<
  ButtonProps,
  'isLoading' | 'isDisable' | 'onClick'
> &
  HTMLStyledProps<typeof StyledLink> & {
    href: string;
  };

const LinkButton = (props: LinkButtonProps) => {
  const { children, href, icon, visual = 'link', ...buttonProps } = props;

  return (
    <StyledLink {...buttonProps} href={href} visual={visual}>
      <>
        {icon}
        {children}
      </>
    </StyledLink>
  );
};

export default LinkButton;
