import { Capacitor } from '@capacitor/core';
import useMedia from 'react-use/esm/useMedia';

export enum Platform {
  WEB = 'web',
  IOS = 'ios',
}

export type AvailablePlatforms = `${Platform}`;

const usePlatform = () => {
  const platform = Capacitor.getPlatform() as AvailablePlatforms;
  const isNative = platform === Platform.IOS;
  const isWeb = platform === Platform.WEB;
  const isStandalone = useMedia('(display-mode: standalone)');

  return {
    isNative,
    isStandalone,
    isWeb,
    platform,
  };
};

export default usePlatform;
