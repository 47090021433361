import { clamp } from '@prtcl/plonk';
import * as RxProgress from '@radix-ui/react-progress';
import { styled } from 'styled-system/jsx';
import { type JsxStyleProps } from 'styled-system/types';

export const Root = styled(RxProgress.Root, {
  base: {
    background: 'zinc.700/50',
    borderRadius: 8,
    height: 2,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
});

const Indicator = styled(RxProgress.Indicator, {
  base: {
    background: 'zinc.700',
    borderRadius: 8,
    height: '100%',
    transition: 'transform 1360ms cubic-bezier(0.65, 0, 0.35, 1)',
    width: '100%',
  },
});

const Progress = (props: RxProgress.ProgressProps & JsxStyleProps) => {
  const { value = 0, max = 1, ...rootProps } = props;
  const progress = clamp(value * 100, max * 100);

  return (
    <Root {...rootProps} value={progress}>
      <Indicator style={{ transform: `translateX(-${100 - progress}%)` }} />
    </Root>
  );
};

export default Progress;
