import * as RxDropdownMenu from '@radix-ui/react-dropdown-menu';
import { animated, config, useTransition } from '@react-spring/web';
import {
  type PropsWithChildren,
  type ReactNode,
  forwardRef,
  useState,
  createContext,
  useContext,
} from 'react';
import { Stack, styled } from 'styled-system/jsx';
import { type JsxStyleProps } from 'styled-system/types';
import { InnerIconButton } from '../Button/IconButton';

type DropdownContextValue = {
  isOpen: boolean;
};

const DropdownContext = createContext<DropdownContextValue>({ isOpen: false });

const InnerItem = styled(RxDropdownMenu.Item, {
  base: {
    color: 'text',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: ['1rem', '0.95rem'],
    flexDirection: 'row',
    display: 'flex',
    px: 3.5,
    transition: 'background-color 168ms linear',
    minHeight: 9,
    outline: 'none',
    _hover: {
      background: 'zinc.50',
    },
    '&[data-highlighted]': {
      background: 'zinc.50',
    },
    '&[data-disabled]': {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  },
});

export type DropdownItemProps = Omit<
  RxDropdownMenu.DropdownMenuItemProps,
  'disabled'
> &
  JsxStyleProps & {
    icon?: ReactNode;
    isDisabled?: boolean;
  };

export const Item = (props: DropdownItemProps) => {
  const { children, icon, isDisabled = false, ...innerProps } = props;

  return (
    <InnerItem {...innerProps} disabled={isDisabled}>
      <Stack direction="row" gap={2} flex={1} alignItems="center">
        {icon} {children}
      </Stack>
    </InnerItem>
  );
};

export const InnerContent = styled(animated(RxDropdownMenu.Content), {
  base: {
    background: 'white',
    borderRadius: 8,
    minWidth: 'fit-content',
    outline: 'none',
    py: 1.5,
    shadow: 'lg',
    width: '10.68rem',
    zIndex: 10,
  },
});

export const Arrow = styled(RxDropdownMenu.Arrow, { base: { fill: 'white' } });

export type DropdownContentProps = Pick<
  RxDropdownMenu.DropdownMenuContentProps,
  'align' | 'alignOffset' | 'side' | 'sideOffset' | 'children'
> &
  JsxStyleProps & {
    hasArrow?: boolean;
  };

export const Content = forwardRef<HTMLDivElement, DropdownContentProps>(
  function DropdownContent(props, ref) {
    const {
      align = 'center',
      children,
      hasArrow = false,
      side = 'bottom',
      sideOffset = 0,
      ...contentProps
    } = props;
    const { isOpen } = useContext(DropdownContext);

    const transitions = useTransition(isOpen, {
      from: { opacity: 0.84, y: -6 },
      enter: { opacity: 1, y: 0 },
      leave: { opacity: 0, y: -6 },
      config: {
        ...config.default,
        mass: 1,
        tension: 200,
        friction: 26,
      },
      expires: true,
    });

    return transitions((styles, shouldRender) => {
      if (shouldRender) {
        return (
          <InnerContent
            align={align}
            forceMount
            loop
            ref={ref}
            side={side}
            sideOffset={sideOffset}
            {...contentProps}
            style={styles}
          >
            {children}
            {hasArrow && <Arrow />}
          </InnerContent>
        );
      }

      return null;
    });
  },
);

export type DropdownRootProps = PropsWithChildren &
  Omit<RxDropdownMenu.DropdownMenuProps, 'open'>;

export const Root = (props: DropdownRootProps) => {
  const { children } = props;
  const [isOpen, toggleOpen] = useState(false);

  return (
    <DropdownContext.Provider value={{ isOpen }}>
      <RxDropdownMenu.Root open={isOpen} onOpenChange={toggleOpen} modal={true}>
        {children}
      </RxDropdownMenu.Root>
    </DropdownContext.Provider>
  );
};

export const Trigger = styled(RxDropdownMenu.Trigger, {
  base: {
    opacity: 1,
    outline: 'none',
    '&[data-state="open"]': {
      opacity: 0.32,
    },
    '&[data-state="closed"]': {
      opacity: 1,
    },
  },
});

// Note: we must use the inner icon button component here instead of the Aria composition so that Radix can bind its own handlers.
export const IconTrigger = forwardRef<
  HTMLButtonElement,
  RxDropdownMenu.DropdownMenuTriggerProps & JsxStyleProps & PropsWithChildren
>(function IconTrigger(props, ref) {
  return (
    <Trigger asChild>
      <InnerIconButton {...props} ref={ref} />
    </Trigger>
  );
});

export const Portal = styled(RxDropdownMenu.Portal, {});

export type DropdownProps = DropdownRootProps & {
  trigger: ReactNode | (() => ReactNode);
};

const Dropdown = (props: DropdownProps) => {
  const { children, trigger, ...rootProps } = props;

  return (
    <Root {...rootProps}>
      {typeof trigger === 'function' ? trigger() : trigger}
      <Portal forceMount>{children}</Portal>
    </Root>
  );
};

export default Dropdown;
