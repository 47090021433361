import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

export type DivinationState = {
  divinationId: string | null;
  isDeleting: boolean;
  isOpen: boolean;
};

const getInitialState = (): DivinationState => ({
  divinationId: null,
  isDeleting: false,
  isOpen: false,
});

export enum Actions {
  VIEW_DIVINATION = 'VIEW_DIVINATION',
  CLOSE = 'CLOSE',
  TOGGLE_DELETING = 'TOGGLE_DELETING',
}

type DivinationActions =
  | { type: Actions.VIEW_DIVINATION; payload: { divinationId: string } }
  | { type: Actions.CLOSE }
  | { type: Actions.TOGGLE_DELETING; payload: boolean };

const reducer = (
  state: DivinationState,
  action: DivinationActions,
): DivinationState => {
  switch (action.type) {
    case Actions.VIEW_DIVINATION: {
      return {
        ...getInitialState(),
        divinationId: action.payload.divinationId,
        isOpen: true,
      };
    }
    case Actions.CLOSE: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case Actions.TOGGLE_DELETING: {
      return {
        ...state,
        isDeleting: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const useDivinationViewApi = () => {
  const [state, dispatch] = useReducer(reducer, {}, () => getInitialState());

  const viewDivinationResult = useCallback(
    (divinationId: string) => {
      dispatch({ type: Actions.VIEW_DIVINATION, payload: { divinationId } });
    },
    [dispatch],
  );

  const closeViewer = useCallback(
    () => dispatch({ type: Actions.CLOSE }),
    [dispatch],
  );

  return useMemo(
    () => ({
      ...state,
      closeViewer,
      dispatch,
      viewDivinationResult,
    }),
    [state, dispatch, closeViewer, viewDivinationResult],
  );
};

export type DivinationContextValue = ReturnType<typeof useDivinationViewApi>;

export const DivinationContext = createContext<DivinationContextValue>(
  {} as DivinationContextValue,
);

export const useDivinationView = () => useContext(DivinationContext);
