import {
  Root,
  Item,
  Content,
  Trigger,
  type AccordionProps,
  type AccordionTriggerProps,
} from './Accordion';

export { type AccordionProps, type AccordionTriggerProps };

export default Object.assign(Root, {
  Root,
  Item,
  Content,
  Trigger,
});
