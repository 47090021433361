import Layout from './Layout';
import ListView from './ListView';

const ListViewScreen = () => {
  return (
    <Layout>
      <ListView />
    </Layout>
  );
};

export default ListViewScreen;
