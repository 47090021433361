import { type PropsWithChildren } from 'react';
import { useLocation } from 'wouter';
import { Spacer } from 'styled-system/jsx';
import { SearchIcon, SettingsIcon } from '@ichingio/icons';
import { IconButton } from '@ichingio/ui';
import { useBreakpoints, usePlatform } from '@ichingio/viewport';
import ActionBar from '~/components/ActionBar';
import ActionMenu from '~/components/ActionMenu';
import { Layout, FloatingHeader } from '~/lib/layout';
import { SETTINGS_ROUTE } from '~/lib/routes';
import { useNavigateTrigger } from '~/modals/Search';

const SearchTrigger = () => {
  const { openSearchModal } = useNavigateTrigger();

  return (
    <IconButton onPress={() => openSearchModal()}>
      <SearchIcon />
    </IconButton>
  );
};

const Settings = () => {
  const [_, navigate] = useLocation();

  return (
    <IconButton onPress={() => navigate(SETTINGS_ROUTE)}>
      <SettingsIcon size="lg" />
    </IconButton>
  );
};

const Header = () => {
  const { breakpoint } = useBreakpoints();
  const { isStandalone, isNative } = usePlatform();

  return (
    <FloatingHeader>
      <FloatingHeader.Content pl={[3, 5]} pr={[2, 3, 3, 4]}>
        <Spacer />
        <ActionMenu />
        {(breakpoint > 3 || (!isNative && !isStandalone)) && <SearchTrigger />}
        <Settings />
      </FloatingHeader.Content>
    </FloatingHeader>
  );
};

const SharedLayout = (props: PropsWithChildren) => {
  const { children } = props;
  const { isStandalone, isNative } = usePlatform();

  return (
    <Layout.Root>
      <Header />
      <Layout.Main>{children}</Layout.Main>
      {isNative || isStandalone ? <ActionBar /> : null}
    </Layout.Root>
  );
};

export default SharedLayout;
