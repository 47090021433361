import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type LayoutState = {
  contentElement: HTMLDivElement | null;
  scrollElement: HTMLDivElement | null;
};

export const useLayoutContextApi = () => {
  const [state, setState] = useState<LayoutState>(() => ({
    contentElement: null,
    scrollElement: null,
  }));

  const setScrollElement = useCallback((scrollElement: HTMLDivElement) => {
    setState((prevState) => ({
      ...prevState,
      scrollElement,
    }));
  }, []);

  const setContentElement = useCallback((contentElement: HTMLDivElement) => {
    setState((prevState) => ({
      ...prevState,
      contentElement,
    }));
  }, []);

  return useMemo(
    () => ({
      ...state,
      setContentElement,
      setScrollElement,
    }),
    [state, setContentElement, setScrollElement],
  );
};

export type LayoutContextApi = ReturnType<typeof useLayoutContextApi>;

export const LayoutContext = createContext<LayoutContextApi>(
  {} as LayoutContextApi,
);

export const useLayoutContext = () => useContext(LayoutContext);
