import { useAuthActions } from '@convex-dev/auth/react';
import { useConvexAuth, useQuery } from 'convex/react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { api } from '@ichingio/server/api';
import { AuthProviders } from '@ichingio/types';
import { sanitizeEmailAddress } from '@ichingio/utils';

export const useAuthenticationApi = () => {
  const { isAuthenticated, isLoading: isConvexAuthLoading } = useConvexAuth();
  const { signIn, signOut } = useAuthActions();
  const user = useQuery(api.users.getCurrentUser);
  const isLoading = isConvexAuthLoading;

  const login = useCallback(
    async (identifier: string) => {
      const email = sanitizeEmailAddress(identifier);
      const res = await signIn(AuthProviders.RESEND_OTP, { email });

      return { email, ...res };
    },
    [signIn],
  );

  const verifyCode = useCallback(
    async (opts: { identifier: string; validationCode: string }) => {
      const { identifier, validationCode } = opts;
      const email = sanitizeEmailAddress(identifier);

      const res = await signIn(AuthProviders.RESEND_OTP, {
        email,
        code: validationCode,
      });

      return { email, ...res };
    },
    [signIn],
  );

  const logout = useCallback(async () => await signOut(), [signOut]);

  return useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      login,
      verifyCode,
      logout,
      user,
      userId: user ? user._id : null,
    }),
    [isAuthenticated, isLoading, login, logout, user, verifyCode],
  );
};

export type AuthenticationContextApi = ReturnType<typeof useAuthenticationApi>;

export const AuthenticationContext = createContext<AuthenticationContextApi>(
  {} as AuthenticationContextApi,
);

const useAuthentication = () => useContext(AuthenticationContext);

export default useAuthentication;
