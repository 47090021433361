// src/index.ts
var AuthProviders = /* @__PURE__ */ ((AuthProviders2) => {
  AuthProviders2["RESEND_OTP"] = "resend-otp";
  return AuthProviders2;
})(AuthProviders || {});
var FeatureFlags = /* @__PURE__ */ ((FeatureFlags2) => {
  FeatureFlags2["ALLOW_REGISTRATION"] = "isUserRegistrationEnabled";
  FeatureFlags2["SHARED_DIVINATIONS"] = "isSharedDivinationsEnabled";
  return FeatureFlags2;
})(FeatureFlags || {});
var SearchMode = /* @__PURE__ */ ((SearchMode2) => {
  SearchMode2["ALL"] = "all";
  SearchMode2["DIVINATIONS"] = "divinations";
  SearchMode2["JOURNALS"] = "journals";
  return SearchMode2;
})(SearchMode || {});
var Yao = /* @__PURE__ */ ((Yao2) => {
  Yao2[Yao2["MOVING_YANG"] = 9] = "MOVING_YANG";
  Yao2[Yao2["MOVING_YIN"] = 6] = "MOVING_YIN";
  Yao2[Yao2["YANG"] = 7] = "YANG";
  Yao2[Yao2["YIN"] = 8] = "YIN";
  return Yao2;
})(Yao || {});
var SearchItemType = /* @__PURE__ */ ((SearchItemType2) => {
  SearchItemType2["DIVINATION"] = "divination";
  SearchItemType2["HEXAGRAM"] = "hexagram";
  SearchItemType2["JOURNAL"] = "journal";
  return SearchItemType2;
})(SearchItemType || {});

export { AuthProviders, FeatureFlags, SearchItemType, SearchMode, Yao };
