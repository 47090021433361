import { styled } from 'styled-system/jsx';

const Heading = styled('h2', {
  base: {
    color: 'text',
  },
  variants: {
    size: {
      sm: { fontSize: '1.125rem' },
      md: { fontSize: '1.25rem' },
      lg: { fontSize: '1.35rem' },
      xl: { fontSize: '1.5rem' },
      '2xl': { fontSize: '1.65rem' },
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export default Heading;
