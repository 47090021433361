import { useRef, type PropsWithChildren, Suspense, lazy } from 'react';
import { SearchContext, useSearchApi } from './hooks/useSearch';

const Search = lazy(() => import('./SearchModal'));

const SearchProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const context = useSearchApi();
  const hasOpenedOnce = useRef(false);

  if (context.isOpen && !hasOpenedOnce.current) {
    hasOpenedOnce.current = true;
  }

  return (
    <SearchContext.Provider value={context}>
      {children}
      <Suspense>{hasOpenedOnce.current && <Search />}</Suspense>
    </SearchContext.Provider>
  );
};

export default SearchProvider;
