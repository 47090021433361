import { type PropsWithChildren } from 'react';
import { BreakpointsContext, useBreakpointsApi } from '../hooks/useBreakpoints';

const BreakpointsProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const context = useBreakpointsApi();

  return (
    <BreakpointsContext.Provider value={context}>
      {children}
    </BreakpointsContext.Provider>
  );
};

export default BreakpointsProvider;
