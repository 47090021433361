import { type PropsWithChildren } from 'react';
import {
  AuthenticationContext,
  useAuthenticationApi,
} from './hooks/useAuthentication';

const AuthenticationProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const context = useAuthenticationApi();

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
