import { Stack } from 'styled-system/jsx';
import { Heading } from '@ichingio/ui';
import { useBreakpoints } from '@ichingio/viewport';
import EmptyState from '~/components/EmptyState';
import { PageContent } from '~/lib/layout';

const Shared = () => {
  const { breakpoint } = useBreakpoints();

  return (
    <PageContent>
      <Stack direction="column" gap={6} px={[3.5, 5]}>
        <Stack direction="row" gap={1.5}>
          <Heading fontWeight={500} size={breakpoint === 1 ? '2xl' : 'lg'}>
            Shared
          </Heading>
        </Stack>
        <EmptyState
          title="No Shared Divinations"
          description=" Your list of shared divinations will appear here"
        />
      </Stack>
    </PageContent>
  );
};

export default Shared;
