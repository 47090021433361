import { usePrevious } from '@prtcl/plonk-hooks';
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';
import { useRefGetter } from '@ichingio/hooks';
import { useLayoutContext } from './useLayoutContext';

const useScrollObserver = (onNearlyEnd: () => void) => {
  const { scrollElement } = useLayoutContext();
  const [nearlyEnded, setNearlyEnded] = useState(false);
  const prevNearlyEnded = usePrevious(nearlyEnded);
  const getNearlyEnded = useRefGetter(nearlyEnded);
  const getCallback = useRefGetter(onNearlyEnd);

  useEffect(() => {
    if (nearlyEnded && nearlyEnded !== prevNearlyEnded) {
      const cb = getCallback();

      if (cb) {
        cb();
      }
    }
  }, [getCallback, nearlyEnded, prevNearlyEnded]);

  useEffect(() => {
    if (scrollElement) {
      const handleScroll = throttle(() => {
        const threshold = scrollElement.offsetHeight / 2;
        const pos = scrollElement.offsetHeight + scrollElement.scrollTop;
        const current = getNearlyEnded();
        const updates =
          pos >= scrollElement.scrollHeight - threshold &&
          scrollElement.scrollTop !== 0;

        if (current !== updates) {
          setNearlyEnded(updates);
        }
      }, 50);

      handleScroll();

      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollElement, getNearlyEnded]);
};

export default useScrollObserver;
