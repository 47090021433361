import { Suspense, lazy, useRef, type PropsWithChildren } from 'react';
import {
  useDivinationViewApi,
  DivinationContext,
} from './hooks/useDivinationView';

const DivinationView = lazy(() => import('./DivinationView'));

const DivinationProvider = (props: PropsWithChildren) => {
  const context = useDivinationViewApi();
  const hasOpenedOnce = useRef(false);

  if (context.isOpen && !hasOpenedOnce.current) {
    hasOpenedOnce.current = true;
  }

  return (
    <DivinationContext.Provider value={context}>
      {props.children}
      <Suspense>{hasOpenedOnce.current && <DivinationView />}</Suspense>
    </DivinationContext.Provider>
  );
};

export default DivinationProvider;
