import { type PropsWithChildren } from 'react';
import { Flex, styled, type FlexProps } from 'styled-system/jsx';
import { type JsxStyleProps } from 'styled-system/types';
import { useLayoutContext } from './hooks/useLayoutContext';

export const Main = (props: PropsWithChildren & JsxStyleProps) => {
  const { children, ...mainProps } = props;
  const { setScrollElement } = useLayoutContext();

  return (
    <styled.main
      key="Layout-Main"
      ref={setScrollElement}
      alignItems="center"
      pt="calc(env(safe-area-inset-top) + 3rem)"
      width="100%"
      overflowX="hidden"
      overflowY="auto"
      position="relative"
      {...mainProps}
    >
      {children}
    </styled.main>
  );
};

export const PageContent = (props: PropsWithChildren & FlexProps) => {
  const { children, ...flexProps } = props;
  const { setContentElement } = useLayoutContext();

  return (
    <Flex
      key="Layout-PageContent"
      ref={setContentElement}
      flexDirection="column"
      maxWidth="128rem"
      minHeight="100%"
      mx="auto"
      pb="6rem"
      width="100%"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

export const Root = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <Flex
      key="Layout-Root"
      alignItems="center"
      flexDirection="column"
      height="100%"
      id="ichingio-root"
      width="100%"
      position="absolute"
      inset={0}
      zIndex={0}
    >
      {children}
    </Flex>
  );
};
