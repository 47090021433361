import { useCallback, useRef } from 'react';

const useRefGetter = <T = unknown>(val: T): (() => T) => {
  const valRef = useRef(val);
  valRef.current = val;

  return useCallback(() => valRef.current, []);
};

export default useRefGetter;
