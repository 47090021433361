import { useCallback } from 'react';
import { useLocation, useRoute } from 'wouter';
import {
  ComponentsIcon,
  LighteningIcon,
  SearchIcon,
  SharedIcon,
} from '@ichingio/icons';
import { wait } from '@ichingio/nn';
import { IconButton } from '@ichingio/ui';
import { FloatingFooter, useLayoutContext } from '~/lib/layout';
import { LIST_DIVINATIONS_ROUTE, LIST_SHARED_ROUTE } from '~/lib/routes';
import { useDivinationFlow } from '~/modals/DivinationFlow';
import { useNavigateTrigger } from '~/modals/Search';

const ActionBar = () => {
  const [_, navigate] = useLocation();
  const { scrollElement } = useLayoutContext();
  const { startNewDivination } = useDivinationFlow();
  const { openSearchModal } = useNavigateTrigger();
  const [isShared] = useRoute(LIST_SHARED_ROUTE);

  const scrollUp = useCallback(() => {
    if (scrollElement) {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [scrollElement]);

  return (
    <FloatingFooter>
      <FloatingFooter.Content>
        <FloatingFooter.Item>
          <IconButton
            onPress={async () => {
              if (isShared) {
                navigate(LIST_DIVINATIONS_ROUTE);
              }

              await wait(50);
              scrollUp();
            }}
          >
            <ComponentsIcon size="xl" color="neutral.200" />
          </IconButton>
        </FloatingFooter.Item>
        <FloatingFooter.Item>
          <IconButton
            onPress={async () => {
              if (!isShared) {
                navigate(LIST_SHARED_ROUTE);
              }

              await wait(50);
              scrollUp();
            }}
          >
            <SharedIcon size="xl" color="neutral.200" />
          </IconButton>
        </FloatingFooter.Item>
        <FloatingFooter.Item>
          <IconButton onPress={() => startNewDivination()}>
            <LighteningIcon size="xl" color="neutral.200" />
          </IconButton>
        </FloatingFooter.Item>
        <FloatingFooter.Item>
          <IconButton onPress={() => openSearchModal()}>
            <SearchIcon size="xl" color="neutral.200" />
          </IconButton>
        </FloatingFooter.Item>
      </FloatingFooter.Content>
    </FloatingFooter>
  );
};

export default ActionBar;
