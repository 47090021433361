import { type RecipeVariantProps, cva } from 'styled-system/css';

export const buttonStyle = cva({
  base: {
    alignItems: 'center',
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    outline: 'none',
    transition: 'background-color 168ms cubic-bezier(.79,.31,.59,.83)',
    userSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
    width: 'fit-content',
    _disabled: {
      cursor: 'not-allowed',
      opacity: '0.35 !important',
      '@media (hover: hover)': {
        _hover: { opacity: 0.35 },
      },
    },
    '@media (hover: none)': {
      _hover: { opacity: 1 },
    },
    '@media (hover: hover)': {
      _hover: { opacity: 0.95 },
    },
    '& svg': {
      ml: -1,
    },
  },
  variants: {
    visual: {
      solid: {
        color: 'white',
        background: 'neutral.800',
        outline: '1px solid var(--colors-neutral-800)',
      },
      outline: {
        color: 'text',
        outline: '1px solid {colors.border.darker}',
        _active: {
          background: 'zinc.100',
        },
      },
      light: {
        color: 'text',
        background: 'white',
        outline: '1px solid white',
      },
      ghost: {
        bg: 'transparent',
        color: 'text',
      },
      link: {
        bg: 'transparent',
        color: 'text',
        padding: 0,
        minHeight: 'auto',
        textDecoration: 'underline',
        justifyContent: 'start',
        _hover: {
          textDecoration: 'none',
        },
      },
    },
    size: {
      sm: {
        px: 4,
        fontSize: '0.95rem',
        minHeight: 9,
      },
      md: {
        px: 5,
        fontSize: 'md',
        minHeight: 10,
      },
      lg: {
        px: 6,
        fontSize: 'lg',
        minHeight: 12,
      },
    },
  },
  defaultVariants: {
    visual: 'solid',
    size: 'md',
  },
});

export type ButtonVariantProps = RecipeVariantProps<typeof buttonStyle>;
