import Layout from './Layout';
import Shared from './Shared';

const SharedScreen = () => {
  return (
    <Layout>
      <Shared />
    </Layout>
  );
};

export default SharedScreen;
