import { DateTime } from 'luxon';
import { memo } from 'react';
import { Flex } from 'styled-system/jsx';
import { type ListDivinationsItem } from '@ichingio/types';
import {
  GridItem,
  Hexagram,
  R2Image,
  type ImageTransformOptions,
} from '@ichingio/ui';
import { getDivinationRoute } from '~/lib/routes';

const imageOptions: ImageTransformOptions = {
  width: 340,
};

const DivinationGridItem = memo(
  function DivinationGridItem(props: {
    item: ListDivinationsItem;
    onNavigate: (to: string) => void;
  }) {
    const { item, onNavigate } = props;

    return (
      <GridItem>
        <GridItem.Content
          onPress={() => onNavigate(getDivinationRoute(item.data.divinationId))}
          shadow={['lg', 'lg', 'lg', 'none']}
        >
          <R2Image src={item.data.imageUrl} options={imageOptions} />
          <Flex
            alignItems="center"
            justifyContent="center"
            inset={0}
            position="absolute"
          >
            <Hexagram
              width={['82%', '78%', '78%', '76%', '78%']}
              height={['68%', '63%', '63%', '62%', '65%']}
            >
              {item.data.yao.map((yao, index) => {
                return (
                  <Hexagram.Line
                    key={`${yao}-${index}`}
                    yao={yao}
                    visual={
                      (item.data.imageBrightness || 0) > 0.5
                        ? 'alphaDark'
                        : 'alphaLight'
                    }
                  />
                );
              })}
            </Hexagram>
          </Flex>
        </GridItem.Content>
        <GridItem.Description>
          {DateTime.fromMillis(item.data.createdAt).toLocaleString(
            DateTime.DATETIME_MED,
          )}
        </GridItem.Description>
      </GridItem>
    );
  },
  (prevProps, nextProps) => prevProps.item.id === nextProps.item.id,
);

export default DivinationGridItem;
