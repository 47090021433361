import { useMemo } from 'react';
import { useBreakpoints, type Breakpoint } from '@ichingio/viewport';

const getColsForBreakpoint = (breakpoint: Breakpoint): number => {
  if (breakpoint >= 5) {
    return 6;
  }
  if (breakpoint === 4) {
    return 5;
  }
  if (breakpoint === 3) {
    return 4;
  }

  return 2;
};

const usePageSize = () => {
  const { breakpoint } = useBreakpoints();

  return useMemo(() => {
    const gridColumnCount = getColsForBreakpoint(breakpoint);
    const initialPage = gridColumnCount * 5;
    const nextPage = initialPage;

    return {
      gridColumnCount,
      initialPage,
      nextPage,
    };
  }, [breakpoint]);
};

export default usePageSize;
