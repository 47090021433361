import { DateTime } from 'luxon';
import { memo } from 'react';
import { Box } from 'styled-system/jsx';
import { type ListJournalsItem } from '@ichingio/types';
import { GridItem } from '@ichingio/ui';
import { removeDoubleBreaks, truncateText } from '@ichingio/utils';
import { useBreakpoints } from '@ichingio/viewport';
import { getJournalRoute } from '~/lib/routes';

const getMaxContentLength = (breakpoint: number) => {
  switch (breakpoint) {
    case 1:
    case 2: {
      return 140;
    }
    case 3: {
      return 135;
    }
    case 4: {
      return 160;
    }
    case 5: {
      return 170;
    }
    case 6: {
      return 260;
    }
    default: {
      return 380;
    }
  }
};

const JournalGridItem = memo(
  function JournalGridItem(props: {
    item: ListJournalsItem;
    onNavigate: (to: string) => void;
  }) {
    const { item, onNavigate } = props;
    const { breakpoint } = useBreakpoints();

    return (
      <GridItem>
        <GridItem.Content
          onPress={() => onNavigate(getJournalRoute(item.data.journalId))}
          p={[2, 3]}
          pr={[3, 4]}
          shadow="lg"
        >
          <Box
            color="text"
            fontSize="xs"
            height="calc(100% - var(--spacing-2))"
            lineHeight="1.5em"
            overflow="hidden"
            overflowWrap="break-word"
            whiteSpace="pre-wrap"
            wordBreak="break-word"
            dangerouslySetInnerHTML={{
              __html: item.data.preview
                ? truncateText(
                    removeDoubleBreaks(item.data.preview),
                    getMaxContentLength(breakpoint),
                  )
                : '',
            }}
          />
        </GridItem.Content>
        <GridItem.Description>
          {DateTime.fromMillis(item.data.createdAt).toLocaleString(
            DateTime.DATETIME_MED,
          )}
        </GridItem.Description>
      </GridItem>
    );
  },
  (prevProps, nextProps) =>
    prevProps.item.id === nextProps.item.id &&
    prevProps.item.data.preview === nextProps.item.data.preview,
);

export default JournalGridItem;
