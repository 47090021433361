export enum CharCodes {
  BREAK = 10,
  SPACE = 32,
}

export enum KeyCodes {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ENTER = 'Enter',
  ESC = 'Escape',
}

const probablyNumChars = new Set([
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '#',
  '.',
  ':',
]);

export const isProbablyNumber = (s: string): boolean => {
  const end = s.length;
  let index = 0;

  while (index < end) {
    const char = s.charAt(index);

    if (!probablyNumChars.has(char)) {
      return false;
    }

    index++;
  }

  return true;
};

export const toTitleCase = (s: string) => {
  const res: string[] = [];
  let i = 0;
  let prev;

  while (i < s.length) {
    const char = s.charAt(i);

    if (i === 0 || prev === ' ') {
      res.push(char.toUpperCase());
    } else {
      res.push(char);
    }

    prev = char;
    i++;
  }

  return res.join('');
};

export const findLastBreakingSpace = (
  content: string,
  maxLength = content.length,
) => {
  let index = maxLength - 1;

  while (index) {
    const code = content.charCodeAt(index);

    if (code === CharCodes.BREAK || code === CharCodes.SPACE) {
      return index + 1;
    }

    index--;
  }

  return maxLength;
};

export const removeDoubleBreaks = (text: string) =>
  text.replace(/\n\n/gi, '\n').replace(/\n\n\n/gi, '\n');

const punct = new Set(['.', ',']);

export const truncateText = (content: string, maxLength: number) => {
  if (content.length < maxLength) {
    return content;
  }

  let truncated = content
    .slice(0, findLastBreakingSpace(content, maxLength))
    .trim();
  const last = truncated.charAt(truncated.length - 1);

  if (punct.has(last)) {
    truncated = truncated.slice(0, truncated.length - 1);
  }

  if (content.length !== truncated.length) {
    return `${truncated}...`;
  }

  return truncated;
};

export const normalizeString = (q: string) => q.toLowerCase().trim();

export const sanitizeEmailAddress = (email: string) => {
  const [user, domain] = email.toLowerCase().replace(' ', '').split('@');
  const url = new URL(`http://${domain}`);

  return `${user}@${url.host}`;
};

export const sanitizeUrl = (initialUrl: string) => {
  const stripped = initialUrl
    .replace('javascript:', '')
    .replace(' ', '')
    .trim();
  const [maybeProto, rest] = stripped.split('://');
  const url = new URL(rest ? stripped : `http://${maybeProto}`);

  return url.href;
};

export const sanitizeNumber = (s: string) =>
  parseInt(s.replace(/[^0-9.]/, ''), 10);

export const quantizeNumber = (value: number, quantum: number) => {
  const remainder = value % quantum;
  const sign = Math.sign(value);
  const mod = remainder ? quantum : 0;

  return value - remainder + sign * mod;
};
