import { useMemo } from 'react';
import Image, { type ImageProps } from './Image';

export type ImageTransformOptions = {
  dpr?: number;
  format?: 'jpeg' | 'webp' | 'avif' | 'auto';
  width: number;
};

const defaultOptions: ImageTransformOptions = {
  dpr: 1,
  format: 'auto', // auto will serve avif with webp as a fallback, or jpeg for ancient browsers.
  width: 1024,
};

const serializeOptions = (options: ImageTransformOptions): string =>
  Object.entries({ ...defaultOptions, ...options })
    .reduce((res, [key, val]) => {
      res.push(`${key}=${val}`);
      return res;
    }, [])
    .join(',');

const createR2TransformUrl = (src: string, options: ImageTransformOptions) =>
  `https://iching.io/cdn-cgi/image/${serializeOptions(options)}/${src}`;

export type R2ImageProps = Omit<ImageProps, 'srcSet'> & {
  options: ImageTransformOptions;
  useHighRes?: boolean;
};

const R2Image = (props: R2ImageProps) => {
  const { src, options, useHighRes = false, ...imageProps } = props;

  const srcSet = useMemo(
    () =>
      useHighRes && src
        ? [
            `${createR2TransformUrl(src, options)} 1x`,
            `${createR2TransformUrl(src, { ...options, dpr: 2 })} 2x`,
          ].join(',')
        : undefined,
    [src, options, useHighRes],
  );

  return (
    <Image
      {...imageProps}
      src={src ? createR2TransformUrl(src, options) : undefined}
      srcSet={srcSet}
    />
  );
};

export default R2Image;
