import { createContext, useCallback, useContext, useState } from 'react';
import { SearchMode, type SearchResult } from '@ichingio/types';

export type SelectHandler = (item: SearchResult) => void | Promise<void>;

export type SearchState = {
  disabledIds: Set<string> | null;
  isOpen: boolean;
  itemSelectHandlers: SelectHandler[];
  mode: SearchMode;
};

const getInitialState = (): SearchState => ({
  disabledIds: null,
  isOpen: false,
  itemSelectHandlers: [],
  mode: SearchMode.ALL,
});

export type TriggerSearchArgs = Pick<Partial<SearchState>, 'mode'> & {
  disabledIds?: string[];
  onSelect?: SelectHandler;
};

export const useSearchApi = () => {
  const [state, setState] = useState(() => getInitialState());

  const openSearchModal = useCallback((args: TriggerSearchArgs) => {
    const { disabledIds = [], mode = SearchMode.ALL, onSelect } = args;

    setState(() => ({
      ...getInitialState(),
      disabledIds: new Set(disabledIds),
      isOpen: true,
      itemSelectHandlers: onSelect ? [onSelect] : [],
      mode,
    }));
  }, []);

  const close = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }, []);

  return {
    ...state,
    close,
    openSearchModal,
  };
};

export type SearchContextApi = ReturnType<typeof useSearchApi>;

export const SearchContext = createContext<SearchContextApi>(
  {} as SearchContextApi,
);

const useSearch = () => useContext(SearchContext);

export default useSearch;
