import {
  forwardRef,
  type ChangeEventHandler,
  type ClipboardEventHandler,
  type KeyboardEventHandler,
  type MouseEventHandler,
  type ReactNode,
  useCallback,
  useState,
} from 'react';
import { type ChangeHandler } from 'react-hook-form';
import { Box } from 'styled-system/jsx';
import { KeyCodes } from '@ichingio/utils';
import Text from '../Text';
import TextField from '../TextField';

const CODE_LENGTH = 6;

const ACCEPTABLE_CHARS = new Set([
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
]);

const isAcceptableNumber = (val: string) => ACCEPTABLE_CHARS.has(val);

const generateId = () => `${Math.random()}`;

type VerificationChar = {
  id: string;
  value: string | undefined;
};

export interface VerificationCodeFieldProps {
  children?: (args: {
    onPaste: MouseEventHandler<HTMLButtonElement>;
    isPasteSupported: boolean;
  }) => ReactNode;
  error?: string;
  label?: string;
  name?: string;
  onChange?: ChangeHandler;
  onVerify?: (changes: string) => Promise<void> | void;
  value?: string;
}

const VerificationCodeField = forwardRef<
  HTMLInputElement,
  VerificationCodeFieldProps
>(function VerificationCodeField(props: VerificationCodeFieldProps, ref) {
  const {
    children,
    error,
    label,
    onChange,
    onVerify,
    value: initialValue,
  } = props;

  const isPasteSupported = typeof navigator?.clipboard?.readText === 'function';

  const [chars, setChars] = useState<VerificationChar[]>(() => {
    return new Array(CODE_LENGTH).fill(undefined).map((_, index) => {
      const val = initialValue?.charAt(index) || '';

      return {
        id: generateId(),
        value: isAcceptableNumber(val) ? val : '',
      };
    });
  });

  const updateChars = useCallback(
    (value: string, targetCharId?: string) => {
      const updatedChars = chars.map((prevChar, index) => {
        if (targetCharId) {
          if (targetCharId === prevChar.id) {
            return {
              ...prevChar,
              value: isAcceptableNumber(value) ? value : '',
            };
          }

          return prevChar;
        }

        const val = value.charAt(index) || '';

        return {
          ...prevChar,
          value: isAcceptableNumber(val) ? val : '',
        };
      });

      const updates = updatedChars
        .map((c) => c.value)
        .filter(Boolean)
        .join('');

      setChars(updatedChars);

      if (onChange) {
        onChange({
          target: {
            value: updates,
          },
        });
      }

      if (updates.length === CODE_LENGTH && onVerify) {
        onVerify(updates);
      }
    },
    [chars, onChange, onVerify],
  );

  const handleChange = useCallback<
    (char: VerificationChar) => ChangeEventHandler<HTMLInputElement>
  >(
    (char) => (e) => {
      e.preventDefault();

      // iOS autofill "from mail" will send a length === 6 string.
      // If this is the case just populate all of the boxes, otherwise target just the focused box ID.
      if (e.target.value.length === 6) {
        updateChars(e.target.value);
      } else {
        updateChars(e.target.value, char.id);
      }
    },
    [updateChars],
  );

  const handlePaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      updateChars(e.clipboardData.getData('text'));
    },
    [updateChars],
  );

  const handleClipboardPaste = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const content = await navigator.clipboard.readText();

      updateChars(content);
    },
    [updateChars],
  );

  const handleKeyUp = useCallback<
    (index: number) => KeyboardEventHandler<HTMLInputElement>
  >(
    (index) => (e) => {
      if (e.key === KeyCodes.DELETE || e.key === KeyCodes.BACKSPACE) {
        const prev = chars[index - 1];

        if (prev) {
          const prevInput = document.querySelector(
            `[data-char-id="${prev.id}"]`,
          );

          if (prevInput instanceof HTMLElement) {
            prevInput.focus();
          }
        }
      } else if (index !== chars.length - 1) {
        const next = chars[index + 1];
        const nextInput = document.querySelector(`[data-char-id="${next.id}"]`);

        if (nextInput instanceof HTMLElement) {
          nextInput.focus();
        }
      }
    },
    [chars],
  );

  return (
    <TextField.Root ariaLabel="Verification Code" gap={2}>
      {label && <TextField.Label>{label}</TextField.Label>}
      <Box display="grid" gridTemplateColumns={6} gap={2} width="100%" mt={1}>
        {chars.map((char, index) => (
          <TextField.Input
            autoComplete="one-time-code"
            data-char-id={char.id}
            height={12}
            bg="neutral.200/75"
            key={char.id}
            maxLength={1}
            onChange={handleChange(char)}
            onKeyUp={handleKeyUp(index)}
            onPaste={handlePaste}
            type="number"
            value={char.value}
            width={12}
            css={{
              appearance: 'textfield',
              textAlign: 'center',
              '&::-webkit-outer-spin-button': {
                appearance: 'none',
                margin: 0,
              },
              '&::-webkit-inner-spin-button': {
                appearance: 'none',
                margin: 0,
              },
            }}
            {...(index === 0 ? { ref } : {})}
          />
        ))}
      </Box>
      {error && <Text color="error">{error}</Text>}
      {children &&
        children({
          isPasteSupported,
          onPaste: handleClipboardPaste,
        })}
    </TextField.Root>
  );
});

export default VerificationCodeField;
