import { useSpring } from '@react-spring/web';
import { usePress } from 'react-aria';
import {
  Divider,
  Flex,
  Spacer,
  Stack,
  type StackProps,
  type DividerProps,
  type FlexProps,
  type SpacerProps,
} from 'styled-system/jsx';
import { useFocusTrap } from '@ichingio/hooks';
import {
  InnerIconButton,
  type InnerIconButtonProps,
} from '../Button/IconButton';

export const ToolbarAction = (
  props: Omit<InnerIconButtonProps, 'onClick'> & {
    onSelect: () => void;
    isActive?: boolean;
    isDisabled?: boolean;
  },
) => {
  const {
    children,
    onSelect,
    isActive = false,
    isDisabled = false,
    ...styledProps
  } = props;
  const { pressProps, isPressed } = usePress({
    onPress: isDisabled ? null : onSelect,
    preventFocusOnPress: true,
    shouldCancelOnPointerExit: true,
    allowTextSelectionOnPress: false,
  });
  const initialOpacity = isDisabled ? 0.35 : 1;
  const styles = useSpring({
    from: {
      opacity: initialOpacity,
    },
    to: {
      opacity: isPressed && !isDisabled ? 0.42 : initialOpacity,
    },
  });

  return (
    <InnerIconButton
      background={isActive && !isDisabled ? 'zinc.700/10' : undefined}
      size="sm"
      style={styles}
      type="button"
      {...pressProps}
      {...styledProps}
    >
      {children}
    </InnerIconButton>
  );
};

export const ToolbarSpacer = (props: SpacerProps) => <Spacer {...props} />;

export const ToolbarDivider = (props: DividerProps) => (
  <Divider
    color="zinc.500"
    height="1.125rem"
    orientation="vertical"
    thickness="1px"
    {...props}
  />
);

export const ToolbarItems = (props: StackProps) => {
  const { children, ...stackProps } = props;
  const bindStackTrap = useFocusTrap();

  return (
    <Stack
      alignItems="center"
      direction="row"
      flex={0}
      gap={1.5}
      justifyContent="start"
      {...bindStackTrap()}
      {...stackProps}
    >
      {children}
    </Stack>
  );
};

export const FloatingToolbar = (props: FlexProps) => {
  const { children, ...flexProps } = props;
  const bindContainerTrap = useFocusTrap();

  return (
    <Flex
      alignItems="center"
      bg="zinc.200"
      justifyContent="center"
      width="100%"
      {...bindContainerTrap()}
      {...flexProps}
    >
      {children}
    </Flex>
  );
};
