import { type PropsWithChildren } from 'react';
import { LayoutContext, useLayoutContextApi } from './hooks/useLayoutContext';

const LayoutProvider = (props: PropsWithChildren) => (
  <LayoutContext.Provider value={useLayoutContextApi()}>
    {props.children}
  </LayoutContext.Provider>
);

export default LayoutProvider;
