import {
  init,
  captureConsoleIntegration,
  ErrorBoundary,
  type ErrorBoundaryProps,
} from '@sentry/react';

const getEnvironment = () => {
  switch (import.meta.env.MODE) {
    case 'production':
      return 'prod';
    case 'preview':
      return 'preview';
    case 'development':
    default:
      return 'dev';
  }
};

export const initSentryClient = () =>
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: getEnvironment(),
    integrations: [captureConsoleIntegration()],
  });

export { ErrorBoundary, type ErrorBoundaryProps };
