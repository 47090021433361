import { createContext, useCallback, useContext, useReducer } from 'react';
import { type GetEditorActions } from '../types';

export type EditorState = {
  autoFocus: boolean;
  getEditorActions: GetEditorActions | null;
  isOpen: boolean;
  journalId: string | null;
};

const getInitialState = (): EditorState => ({
  autoFocus: false,
  getEditorActions: null,
  isOpen: false,
  journalId: null,
});

export enum Actions {
  OPEN_EDITOR = 'OPEN_EDITOR',
  CLOSE_EDITOR = 'CLOSE_EDITOR',
  RESET = 'RESET',
}

type EditorActions =
  | {
      type: Actions.OPEN_EDITOR;
      payload: {
        autoFocus: boolean;
        getEditorActions: GetEditorActions | null;
        journalId: string;
      };
    }
  | { type: Actions.CLOSE_EDITOR }
  | { type: Actions.RESET };

const reducer = (state: EditorState, action: EditorActions): EditorState => {
  switch (action.type) {
    case Actions.OPEN_EDITOR: {
      return {
        ...state,
        autoFocus: action.payload.autoFocus || false,
        getEditorActions: action.payload.getEditorActions,
        isOpen: true,
        journalId: action.payload.journalId,
      };
    }
    case Actions.CLOSE_EDITOR: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case Actions.RESET: {
      return getInitialState();
    }
    default: {
      return state;
    }
  }
};

export const useJournalEditorApi = () => {
  const [state, dispatch] = useReducer(reducer, {}, () => getInitialState());

  const openJournalEditor = useCallback(
    (opts: {
      journalId: string;
      autoFocus?: boolean;
      getEditorActions: GetEditorActions;
    }) => {
      const { getEditorActions = null, journalId, autoFocus = false } = opts;

      dispatch({
        type: Actions.OPEN_EDITOR,
        payload: {
          autoFocus,
          getEditorActions,
          journalId,
        },
      });
    },
    [dispatch],
  );

  const closeEditor = useCallback(() => {
    dispatch({ type: Actions.CLOSE_EDITOR });
    // TODO: find out why Radix occasionally doesn't reset this.
    document.body.style.pointerEvents = 'initial';
  }, [dispatch]);

  return {
    ...state,
    closeEditor,
    dispatch,
    openJournalEditor,
  };
};

export type JournalEditorApi = ReturnType<typeof useJournalEditorApi>;

export const JournalEditorContext = createContext<JournalEditorApi>(null);

export const useJournalEditor = () => useContext(JournalEditorContext);
