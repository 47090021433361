import { useEffect, useRef, useState } from 'react';

const useDeferredValue = <V = unknown>(
  updates: V,
  initialValue: V,
  delay = 0,
) => {
  const [value, setValue] = useState<V>(() => initialValue);
  const timer = useRef(null);

  useEffect(() => {
    if (updates !== value) {
      const cachedValue = updates;

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => setValue(cachedValue), delay);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [updates, value, delay]);

  return value;
};

export default useDeferredValue;
