import {
  Flex,
  styled,
  type FlexProps,
  type HTMLStyledProps,
} from 'styled-system/jsx';

export const Item = (props: FlexProps) => {
  const { children, ...flexProps } = props;

  return (
    <Flex
      alignItems="center"
      flexGrow={1}
      flexShrink={0}
      justifyContent="center"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

export const Content = (props: FlexProps) => {
  const { children, ...stackProps } = props;

  return (
    <Flex
      alignItems="center"
      direction="row"
      flexGrow={1}
      flexShrink={0}
      justifyContent="space-between"
      pb={1}
      pt={2}
      px={5}
      width="100%"
      {...stackProps}
    >
      {children}
    </Flex>
  );
};

export const FloatingFooter = (props: HTMLStyledProps<'footer'>) => {
  const { children, ...footerProps } = props;

  return (
    <styled.footer
      alignItems="center"
      background="neutral.900"
      bottom={0}
      justifyContent="center"
      pb="env(safe-area-inset-bottom)"
      position="fixed"
      userSelect="none"
      width="100%"
      zIndex={10}
      {...footerProps}
    >
      {children}
    </styled.footer>
  );
};
