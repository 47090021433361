import {
  FloatingToolbar,
  ToolbarAction,
  ToolbarDivider,
  ToolbarItems,
  ToolbarSpacer,
} from './FloatingToolbar';

export default Object.assign(FloatingToolbar, {
  Action: ToolbarAction,
  Divider: ToolbarDivider,
  Items: ToolbarItems,
  Spacer: ToolbarSpacer,
});
