import { lazy, Suspense, type PropsWithChildren, useRef } from 'react';
import {
  DivinationContext,
  useDivinationFlowApi,
} from './hooks/useDivinationFlow';

const DivinationFlow = lazy(() => import('./DivinationFlow'));

const DivinateProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const context = useDivinationFlowApi();
  const hasOpenedOnce = useRef(false);

  if (context.isOpen && !hasOpenedOnce.current) {
    hasOpenedOnce.current = true;
  }

  return (
    <DivinationContext.Provider value={context}>
      {children}
      <Suspense>{hasOpenedOnce.current && <DivinationFlow />}</Suspense>
    </DivinationContext.Provider>
  );
};

export default DivinateProvider;
