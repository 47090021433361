import { type BoxProps, Stack } from 'styled-system/jsx';
import { Card, Heading, Text } from '@ichingio/ui';

const EmptyState = (
  props: BoxProps & { title: string; description?: string },
) => {
  const { children, title, description, ...cardProps } = props;

  return (
    <Card {...cardProps}>
      <Card.Body bg="zinc.50" p={3}>
        <Stack gap={3}>
          <Stack gap={1}>
            <Heading size="sm">{title}</Heading>
            {description && (
              <>
                <Text color="text/95">{description}</Text>
              </>
            )}
          </Stack>
          {children}
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default EmptyState;
