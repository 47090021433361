import { type Photo } from '@capacitor/camera';
import { useContext, createContext, useCallback, useReducer } from 'react';
import { type NeuralNetwork } from '@ichingio/nn';
import { type ImageId } from '@ichingio/types';

export enum Step {
  COMPLETE = 'COMPLETE',
  DIVINATING = 'DIVINATING',
  IMAGE_PROCESSING = 'IMAGE_PROCESSING',
  PREVIEW = 'PREVIEW',
  SAVING = 'SAVING',
  SELECTION = 'SELECTION',
}

export type DivinationState = {
  imageData: Float64Array | null;
  imageId: string | null;
  isOpen: boolean;
  networkData: Float64Array | null;
  nn: NeuralNetwork | null;
  selectedImage: Photo | null;
  step: Step;
  targetJournalId: string | null;
};

const getInitialState = (): DivinationState => ({
  imageData: null,
  imageId: null,
  targetJournalId: null,
  isOpen: false,
  networkData: null,
  nn: null,
  selectedImage: null,
  step: Step.SELECTION,
});

export enum Actions {
  CLOSE = 'CLOSE',
  SET_COMPLETE = 'SET_COMPLETE',
  SET_DIVINATING = 'SET_DIVINATING',
  SET_IMAGE_PROCESSING = 'SET_IMAGE_PROCESSING',
  SET_SAVING = 'SET_SAVING',
  SET_SELECTED_IMAGE = 'SET_SELECTED_IMAGE',
  START_DIVINATION = 'START_DIVINATION',
}

type DivinationActions =
  | {
      type: Actions.START_DIVINATION;
      payload: { targetJournalId?: string };
    }
  | { type: Actions.CLOSE }
  | { type: Actions.SET_SELECTED_IMAGE; payload: Photo }
  | { type: Actions.SET_IMAGE_PROCESSING }
  | {
      type: Actions.SET_DIVINATING;
      payload: { imageData: Float64Array; imageId: ImageId };
    }
  | {
      type: Actions.SET_SAVING;
      payload: { networkData: Float64Array; nn: NeuralNetwork };
    }
  | { type: Actions.SET_COMPLETE };

const reducer = (
  state: DivinationState,
  action: DivinationActions,
): DivinationState => {
  switch (action.type) {
    case Actions.START_DIVINATION: {
      return {
        ...getInitialState(),
        isOpen: true,
        targetJournalId: action.payload.targetJournalId || null,
      };
    }
    case Actions.CLOSE: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case Actions.SET_SELECTED_IMAGE: {
      return {
        ...state,
        selectedImage: action.payload,
        step: Step.PREVIEW,
      };
    }
    case Actions.SET_IMAGE_PROCESSING: {
      return {
        ...state,
        step: Step.IMAGE_PROCESSING,
      };
    }
    case Actions.SET_DIVINATING: {
      return {
        ...state,
        imageData: action.payload.imageData,
        imageId: action.payload.imageId,
        step: Step.DIVINATING,
      };
    }
    case Actions.SET_SAVING: {
      return {
        ...state,
        networkData: action.payload.networkData,
        nn: action.payload.nn,
        step: Step.SAVING,
      };
    }
    case Actions.SET_COMPLETE: {
      return {
        ...state,
        isOpen: false,
        step: Step.COMPLETE,
      };
    }
    default: {
      return state;
    }
  }
};

const isDivinatingSteps = new Set([
  Step.IMAGE_PROCESSING,
  Step.DIVINATING,
  Step.SAVING,
  Step.COMPLETE,
]);

export const useDivinationFlowApi = () => {
  const [state, dispatch] = useReducer(reducer, {}, () => getInitialState());
  const isDivinating = isDivinatingSteps.has(state.step);

  const startNewDivination = useCallback(
    (opts?: { targetJournalId?: string }) => {
      const { targetJournalId } = opts || {};

      dispatch({
        type: Actions.START_DIVINATION,
        payload: { targetJournalId },
      });
    },
    [dispatch],
  );

  const close = useCallback(() => {
    dispatch({ type: Actions.CLOSE });
  }, [dispatch]);

  return {
    ...state,
    close,
    dispatch,
    isDivinating,
    startNewDivination,
  };
};

export type DivinationContextApi = ReturnType<typeof useDivinationFlowApi>;

export const DivinationContext = createContext<DivinationContextApi>(
  {} as DivinationContextApi,
);

export const useDivinationFlow = () => useContext(DivinationContext);
