import * as RxAccordion from '@radix-ui/react-accordion';
import { forwardRef } from 'react';
import { styled } from 'styled-system/jsx';
import { type JsxStyleProps } from 'styled-system/types';
import { TriangleRightIcon } from '@ichingio/icons';

export const Item = styled(RxAccordion.Item, {
  base: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    overflow: 'hidden',
  },
});

export const Content = styled(RxAccordion.Content, {
  base: {
    overflow: 'hidden',
    '&[data-state="open"]': {
      animation: 'accordion-down 126ms cubic-bezier(.79,.31,.59,.83)',
    },
    '&[data-state="closed"]': {
      animation: 'accordion-up 126ms cubic-bezier(.79,.31,.59,.83)',
    },
  },
});

const InnerHeader = styled(RxAccordion.Header, {
  base: {
    display: 'flex',
  },
});

const InnerTrigger = styled(RxAccordion.Trigger, {
  base: {
    alignItems: 'center',
    color: 'text',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 'md',
    fontWeight: 500,
    gap: 1,
    justifyContent: 'start',
    minHeight: 'fit-content',
    opacity: 1,
    padding: 0,
    transition: 'opacity 250ms linear',
    width: '100%',
    _hover: {
      opacity: 0.96,
    },
    '&[data-state="open"] svg': {
      transform: 'rotate(90deg)',
    },
  },
});

const TriggerChevron = styled(TriangleRightIcon, {
  base: {
    ml: '-1',
    transition: 'transform 150ms linear',
  },
  defaultVariants: {
    size: 'md',
  },
});

export type AccordionTriggerProps = RxAccordion.AccordionTriggerProps &
  JsxStyleProps;

export const Trigger = forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  function AccordionTrigger(props, ref) {
    const { children, ...triggerProps } = props;

    return (
      <InnerHeader>
        <InnerTrigger ref={ref} {...triggerProps}>
          <TriggerChevron />
          {children}
        </InnerTrigger>
      </InnerHeader>
    );
  },
);

export type AccordionProps = Omit<JsxStyleProps, 'type'> &
  (RxAccordion.AccordionSingleProps | RxAccordion.AccordionMultipleProps);

export const Root = styled(RxAccordion.Root, {
  base: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
});
