import { useCallback, useState } from 'react';
import { Box, styled } from 'styled-system/jsx';
import { type JsxStyleProps } from 'styled-system/types';

const Img = styled('img', {
  base: {
    aspectRatio: '1 / 1',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 275ms cubic-bezier(.79,.31,.59,.83)',
    width: '100%',
  },
});

export type ImageProps = JsxStyleProps & {
  src?: string;
  srcSet?: string;
  loading?: 'lazy' | 'eager';
};

const Image = (props: ImageProps) => {
  const { src, srcSet, loading = 'lazy', ...boxProps } = props;
  const [hasLoaded, setHasLoaded] = useState(false);
  const handleLoad = useCallback(() => setHasLoaded(true), []);

  return (
    <Box
      width="100%"
      background="zinc.100"
      overflow="hidden"
      userSelect="none"
      {...boxProps}
    >
      <Img
        srcSet={srcSet}
        // NOTE: `src` must come after `srcSet` due to a Safari loading issue:
        //  https://bugs.webkit.org/show_bug.cgi?id=190031
        src={src}
        opacity={hasLoaded ? 1 : 0}
        onLoad={handleLoad}
        loading={loading}
      />
    </Box>
  );
};

export default Image;
