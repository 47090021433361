import Dropdown, {
  Arrow,
  Content,
  IconTrigger,
  Item,
  Portal,
  Root,
  Trigger,
  type DropdownContentProps,
  type DropdownItemProps,
  type DropdownProps,
} from './Dropdown';

export {
  type DropdownProps,
  type DropdownItemProps,
  type DropdownContentProps,
};

export default Object.assign(Dropdown, {
  Arrow,
  Content,
  IconTrigger,
  Item,
  Portal,
  Root,
  Trigger,
});
