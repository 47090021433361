import { useSpring, animated } from '@react-spring/web';
import { useRef } from 'react';
import { type AriaButtonProps, useButton } from 'react-aria';
import { type HTMLStyledProps, styled } from 'styled-system/jsx';

const InnerTouchable = styled(animated.div, {
  base: {
    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
    width: 'fit-content',
  },
});

export type InnerTouchableProps = HTMLStyledProps<typeof InnerTouchable>;
export type TouchableProps = AriaButtonProps & InnerTouchableProps;

export const omitAriaProps = (props: TouchableProps): InnerTouchableProps => {
  const {
    isDisabled: _isDisabled,
    onPress: _onPress,
    onPressStart: _onPressStart,
    onPressEnd: _onPressEnd,
    ...styledProps
  } = props;

  return styledProps;
};

const Touchable = (props: TouchableProps) => {
  const { children, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { buttonProps, isPressed } = useButton(props, ref);

  const styles = useSpring({
    from: {
      opacity: 1,
    },
    to: {
      opacity: isPressed ? 0.42 : 1,
    },
  });

  return (
    <InnerTouchable {...omitAriaProps(rest)} {...buttonProps} style={styles}>
      {children}
    </InnerTouchable>
  );
};

export default Touchable;
