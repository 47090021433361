import { type RecipeVariantProps, cva } from 'styled-system/css';
import {
  Box,
  Flex,
  type FlexProps,
  type StackProps,
  styled,
} from 'styled-system/jsx';
import { Yao } from '@ichingio/types';

const segmentStyles = cva({
  base: {
    width: '100%',
    height: '100%',
  },
  variants: {
    visual: {
      solid: {
        bg: 'rgb(43, 43, 43)',
      },
      alphaLight: {
        bg: 'rgba(255, 255, 255, 0.75)',
      },
      alphaDark: {
        bg: 'rgba(43, 43, 43, 0.64)',
      },
    },
  },
  defaultVariants: {
    visual: 'solid',
  },
});

type SegmentVariants = RecipeVariantProps<typeof segmentStyles>;

export const Segment = styled(Box, segmentStyles);

export type LineProps = Omit<FlexProps, 'children'> &
  SegmentVariants & {
    yao: Yao;
    spacer?: StackProps['width'];
  };

export const Line = (props: LineProps) => {
  const {
    bg: _bg,
    borderRadius = '999px',
    height: yaoHeight = ['11%', '11%', '11%', '12%', '11%'],
    spacer = '33%',
    yao,
    visual,
    ...flexProps
  } = props;

  const renderYaoContent = () => {
    if (yao === Yao.YIN || yao === Yao.MOVING_YIN) {
      return (
        <>
          <Segment borderRadius={borderRadius} visual={visual} />
          <Box bg="transparent" width={spacer} />
          <Segment borderRadius={borderRadius} visual={visual} />
        </>
      );
    }

    return <Segment borderRadius={borderRadius} visual={visual} />;
  };

  return (
    <Flex {...flexProps} direction="row" height={yaoHeight}>
      {renderYaoContent()}
    </Flex>
  );
};

const Hexagram = (props: FlexProps) => {
  const { children, width = '100%', height = '100%', ...stackProps } = props;

  return (
    <Flex
      {...stackProps}
      direction="column"
      width={width}
      height={height}
      justifyContent="space-between"
    >
      {children}
    </Flex>
  );
};

export default Hexagram;
