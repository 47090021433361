import { ConvexAuthProvider } from '@convex-dev/auth/react';
import { ConvexReactClient } from 'convex/react';
import { createRoot } from 'react-dom/client';
import { AuthenticationProvider } from '~/lib/auth';
import { ErrorBoundary, initSentryClient } from '~/lib/errors';
import App from './App';
import './main.css';

initSentryClient();

window.addEventListener('vite:preloadError', (e) => {
  e.preventDefault();
  window.location.reload();
});

const convex = new ConvexReactClient(import.meta.env.VITE_CONVEX_URL);
const root = createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary>
    <ConvexAuthProvider client={convex}>
      <AuthenticationProvider>
        <App />
      </AuthenticationProvider>
    </ConvexAuthProvider>
  </ErrorBoundary>,
);
