import TextField, {
  Description,
  ErrorDisplay,
  Input,
  Label,
  Root,
  useTextField,
  type TextFieldProps,
} from './TextField';

export { type TextFieldProps, useTextField };

export default Object.assign(TextField, {
  Description,
  Error: ErrorDisplay,
  Input,
  Label,
  Root,
});
