import { useEffect, useMemo } from 'react';
import usePrevious from 'react-use/esm/usePrevious';
import { useLocation, useRoute } from 'wouter';
import { useBreakpoints } from '@ichingio/viewport';
import {
  JOURNAL_ROUTE,
  LIST_JOURNALS_ROUTE,
  getDivinationRoute,
} from '~/lib/routes';
import { useDivinationFlow } from '~/modals/DivinationFlow';
import { useJournalEditor } from '~/modals/JournalEditor';

const useEditorRouter = () => {
  const { isMobile } = useBreakpoints();
  const [isJournalRoute, params] = useRoute<{ journalId?: string }>(
    JOURNAL_ROUTE,
  );
  const [location, navigate] = useLocation();
  const { startNewDivination } = useDivinationFlow();
  const {
    openJournalEditor,
    closeEditor,
    isOpen: isEditorOpen,
  } = useJournalEditor();

  const prevLocation = usePrevious(location);
  const prevIsEditorOpen = usePrevious(isEditorOpen);
  const prevIsJournalRoute = usePrevious(isJournalRoute);

  useEffect(() => {
    // Open the editor when navigating to the journal route.
    if (
      isJournalRoute &&
      !prevIsJournalRoute &&
      params?.journalId &&
      !isEditorOpen
    ) {
      openJournalEditor({
        autoFocus: isMobile ? false : true,
        journalId: params.journalId,
        getEditorActions: () => ({
          onViewDivination: ({ connection }) => {
            navigate(getDivinationRoute(connection.data.divinationId));
          },
          onAttachDivination: ({ journalId }) => {
            startNewDivination({ targetJournalId: journalId });

            setTimeout(() => {
              closeEditor();
            }, 340);
          },
        }),
      });
    }
    // Close the editor if the location changes.
    if (isEditorOpen && location !== prevLocation) {
      closeEditor();
    }
    // If closing from the journal route, go back to journals list.
    if (isJournalRoute && !isEditorOpen && prevIsEditorOpen) {
      navigate(LIST_JOURNALS_ROUTE);
    }
  }, [
    closeEditor,
    isEditorOpen,
    isJournalRoute,
    isMobile,
    location,
    navigate,
    openJournalEditor,
    params,
    prevIsEditorOpen,
    prevIsJournalRoute,
    prevLocation,
    startNewDivination,
  ]);

  useEffect(() => {
    return () => {
      closeEditor();
    };
  }, [closeEditor]);

  return useMemo(() => {
    return (to: string) => {
      navigate(to);
    };
  }, [navigate]);
};

export default useEditorRouter;
