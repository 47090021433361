import { type ForwardedRef, type MutableRefObject, forwardRef } from 'react';
import {
  type AriaTextFieldProps,
  useObjectRef,
  useTextField as useAriaTextField,
} from 'react-aria';
import { Stack, type StackProps, styled } from 'styled-system/jsx';
import Text, { type TextProps } from '../Text';

export const Label = styled('label', {
  base: {
    color: 'text',
    fontSize: ['0.95rem', 'md'],
    ml: 0.5,
  },
});

export const Input = styled('input', {
  base: {
    background: 'neutral.200/60',
    border: 'none',
    borderRadius: 8,
    color: 'text',
    outline: '1px solid transparent',
    px: 3,
    py: 2,
    transition: 'all 226ms cubic-bezier(.79,.31,.59,.83)',
    width: '100%',
    _focusWithin: {
      outlineColor: 'neutral.800/75',
      outlineWidth: '1px',
    },
    '&::placeholder': {
      color: 'neutral.400',
    },
  },
});

export type CommonRootProps = { ariaLabel: string };

export const Root = (props: StackProps & CommonRootProps) => {
  const { children, ariaLabel, ...stackProps } = props;

  return (
    <Stack
      aria-label={ariaLabel}
      direction="column"
      gap={1}
      width="100%"
      {...stackProps}
    >
      {children}
    </Stack>
  );
};

export const ErrorDisplay = (props: TextProps) => (
  <Text color="error" size="sm" {...props}>
    {props.children}
  </Text>
);

export const Description = (props: TextProps) => (
  <Text color="text/90" size="sm" ml={0.5} {...props}>
    {props.children}
  </Text>
);

// Spread from rom react-hook-form Controller
export type ControllerFieldProps = {
  invalid?: boolean;
  isTouched?: boolean;
  isDirty?: boolean;
  disabled?: boolean;
};

export interface TextFieldProps
  extends Omit<AriaTextFieldProps, 'children'>,
    ControllerFieldProps {
  error?: string;
  value?: string;
}

export const useTextField = (
  props: TextFieldProps,
  externalRef?:
    | ForwardedRef<HTMLInputElement>
    | MutableRefObject<HTMLInputElement>,
) => {
  const {
    description,
    error,
    label,
    onChange,
    placeholder,
    type = 'text',
  } = props;

  const ref = useObjectRef<HTMLInputElement>(externalRef);
  const ariaLabel = typeof label === 'string' ? label : placeholder || '';

  const {
    descriptionProps,
    errorMessageProps,
    inputProps,
    isInvalid,
    labelProps,
    validationErrors,
    ...res
  } = useAriaTextField(
    {
      ...props,
      'aria-label': ariaLabel,
      description,
      errorMessage: error,
      isDisabled:
        typeof props.isDisabled !== 'undefined'
          ? props.isDisabled
          : props.disabled || false,
      isInvalid:
        typeof props.isInvalid !== 'undefined'
          ? props.isInvalid
          : props.invalid || !!error,
      label: ariaLabel,
      onChange,
      type,
    },
    ref,
  );

  const rootProps: CommonRootProps = {
    ariaLabel,
  };

  const extendedInputProps = {
    ...inputProps,
    placeholder,
    ref,
    type,
  };

  return {
    ...res,
    description,
    descriptionProps,
    error: validationErrors.join(' ') || error,
    errorMessageProps,
    inputProps: extendedInputProps,
    isInvalid,
    label,
    labelProps,
    rootProps,
  };
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(props: TextFieldProps, ref) {
    const {
      description,
      descriptionProps,
      error,
      errorMessageProps,
      inputProps,
      isInvalid,
      label,
      labelProps,
      rootProps,
    } = useTextField(props, ref);

    return (
      <Root {...rootProps}>
        {label && <Label {...labelProps}>{label}</Label>}
        <Input {...inputProps} />
        {isInvalid && (
          <ErrorDisplay {...errorMessageProps}>{error}</ErrorDisplay>
        )}
        {description && !isInvalid && (
          <Description {...descriptionProps}>{description}</Description>
        )}
      </Root>
    );
  },
);

export default TextField;
