import { animated, config, useSpring } from '@react-spring/web';
import throttle from 'lodash/throttle';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';
import { Stack, styled, type StackProps } from 'styled-system/jsx';
import { useRefGetter } from '@ichingio/hooks';
import { quantizeNumber } from '@ichingio/utils';
import { useLayoutContext } from '../../hooks/useLayoutContext';

type HeaderContextValue = { isVisible: boolean };

const HeaderContext = createContext<HeaderContextValue>({ isVisible: true });

export const Content = (props: StackProps) => {
  const { children, ...stackProps } = props;
  const { isVisible } = useContext(HeaderContext);

  const styles = useSpring({
    from: {
      opacity: 1,
    },
    to: {
      opacity: isVisible ? 1 : 0,
    },
    config: config.stiff,
  });

  return (
    <animated.div style={{ ...styles, width: '100%' }}>
      <Stack
        alignItems="center"
        direction="row"
        flex={1}
        gap={0}
        height="3rem"
        py={1}
        userSelect="none"
        width="100%"
        {...stackProps}
      >
        {children}
      </Stack>
    </animated.div>
  );
};

const AnimatedHeader = styled(animated.header);

export const FloatingHeader = (props: PropsWithChildren) => {
  const { children } = props;
  const { scrollElement } = useLayoutContext();
  const [isVisible, toggleVisibility] = useState(true);
  const getIsVisible = useRefGetter(isVisible);

  useEffect(() => {
    if (scrollElement) {
      let prevScrollTop = Math.floor(scrollElement.scrollTop);
      let prevQuantized = prevScrollTop;

      const checkVisibility = throttle(() => {
        const quantized = quantizeNumber(
          Math.round(scrollElement.scrollTop),
          16,
        );

        if (quantized !== prevQuantized) {
          const scrollTop = Math.floor(scrollElement.scrollTop);
          const currentVisibility = getIsVisible();
          const updatedVisibility = scrollTop < 48 || scrollTop < prevScrollTop;

          if (currentVisibility !== updatedVisibility) {
            toggleVisibility(updatedVisibility);
          }

          prevScrollTop = scrollTop;
        }

        prevQuantized = quantized;
      }, 50);

      scrollElement.addEventListener('scroll', checkVisibility);

      return () => {
        scrollElement.removeEventListener('scroll', checkVisibility);
      };
    }
  }, [scrollElement, getIsVisible]);

  const styles = useSpring({
    from: {
      y: '0rem',
    },
    to: {
      y: isVisible ? '0rem' : '-3rem',
    },
    config: { ...config.stiff, clamp: true },
  });

  const context = useMemo<HeaderContextValue>(
    () => ({ isVisible }),
    [isVisible],
  );

  return (
    <HeaderContext.Provider value={context}>
      <AnimatedHeader
        backdropFilter="blur(1rem)"
        background="white/75"
        pt="env(safe-area-inset-top)"
        position="fixed"
        top={0}
        zIndex={10}
        width="100%"
        style={styles}
      >
        {children}
      </AnimatedHeader>
    </HeaderContext.Provider>
  );
};
