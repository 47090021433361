import { useCallback } from 'react';
import { useLocation } from 'wouter';
import { useRefGetter } from '@ichingio/hooks';
import { SearchItemType } from '@ichingio/types';
import { getDivinationRoute, getJournalRoute } from '~/lib/routes';
import useSearch from './useSearch';

const useNavigateTrigger = (props?: { disabledIds?: string[] }) => {
  const { disabledIds } = props || {};
  const [_, navigate] = useLocation();
  const { openSearchModal } = useSearch();
  const getDisabledIds = useRefGetter(disabledIds);

  const trigger = useCallback(() => {
    openSearchModal({
      disabledIds: getDisabledIds(),
      onSelect: (item) => {
        switch (item.type) {
          case SearchItemType.DIVINATION: {
            navigate(getDivinationRoute(item.data.itemId));
            break;
          }
          case SearchItemType.JOURNAL: {
            navigate(getJournalRoute(item.data.itemId));
            break;
          }
        }
      },
    });
  }, [getDisabledIds, navigate, openSearchModal]);

  return {
    openSearchModal: trigger,
  };
};

export default useNavigateTrigger;
